import Popper from 'popper.js/dist/popper';
import jquery from 'jquery/dist/jquery';
import Splide from '@splidejs/splide/dist/js/splide';
// import slick from 'slick-carousel/slick/slick';
import slick from 'slick-carousel/slick/slick.min';
// import Swiper JS
import Swiper from 'swiper/swiper-bundle.min';

require('bootstrap/dist/js/bootstrap.min');
require('jquery-validation');
require('bootstrap-daterangepicker');

window.Popper = Popper;
window.$ = window.jQuery = jquery;
window.Splid = Splide;
window.slick = slick;
window.Swiper = Swiper;

require('@fancyapps/fancybox/dist/jquery.fancybox.min');

//image gallery
$('[data-fancybox="gallery"]').fancybox({
    buttons: [
        "slideShow",
        "thumbs",
        "zoom",
        "fullScreen",
        "close"
    ],
    loop: false,
    protect: true
});
//Header menu
$(document).ready(function() {
    $('#bar-mobile').click(function() {
        $('#header').addClass('show');
    });
    $('.icon-close').click(function(){
        $('#header').removeClass('show')
    });



    $(window).scroll(function(){
        if ($(this).scrollTop() > 1) {
            $('#header').addClass('fixed');
        } else {
            $('#header').removeClass('fixed');
        }
    });


    // sub-meu
    $(document).on('click','.icon-sub',function () {
        $(this).next('ul.sub-menu').slideToggle('500');
        // $(this).find('i').toggleClass('fa-chevron-right fa-chevron-down fas fa-caret-down')
    });

    //Button Back to top

    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            $('#back-to-top').addClass('show');
        } else {
            $('#back-to-top').removeClass('show');
        }
    });

    $('#btn-back-to-top').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '500');
    });
    
});

//Validation
// $(".need-validation").validate({
//     errorClass: "is-invalid",
//     errorPlacement: function (label, element) {
//         $("button[type='submit']").removeClass('btn-progress');
//         $(element).next('span').find('.select2-selection').css({
//             'border': '1px solid #ff5252',
//             'border-radius': '.25rem'
//         });
//     }
// });

/*------validation------*/
(function() {
    'use strict';
    window.addEventListener('load', function() {
        const forms = document.getElementsByClassName('needs-validation');
        const validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);

    if(jQuery().daterangepicker) {
        if($(".datepicker").length) {
            $('.datepicker').daterangepicker({
                locale: {format: 'MM/DD/YYYY'},
                singleDatePicker: true,
                autoUpdateInput: true,
                autoApply: true
            });
        }
    }
})();



